import styles from './login-page.module.scss';
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import { Box as MuiBox } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PAGES } from 'routes/navigation/urls';
import useLayoutService from 'services/layout/LayoutService';
import useRouterService from 'services/router/RouterService';
import useScreenService from 'services/screen/ScreenService';
import useSnackbarService from 'services/snackbar/SnackbarService';
import LanguageSelector from 'small-components/LanguageSelector';
import { BoxSection } from 'stories/components/box-section/BoxSection';
import { Button } from 'stories/components/button/Button';
import { FormCard } from 'stories/components/formCard/FormCard';
import { IconButton } from 'stories/components/icon-button/IconButton';
import { Input } from 'stories/components/input/Input';
import PageBodyContent from 'stories/components/page/page-body/page-body-content/PageBodyContent';

function LoginPage(): JSX.Element {
	const { t } = useTranslation();

	const { isMobile } = useScreenService();
	const { showSnackbar } = useSnackbarService();
	const { navigateTo } = useRouterService();
	const { setLoading, loading } = useLayoutService();

	const descriptionTitleContainer = (
		<MuiBox className={styles.descriptionTitleContainer}>
			<MuiBox className={styles.descriptionTitle}>{t('system.name')}</MuiBox>
			<MuiBox className={styles.descriptionSubTitle}>
				{t('login.descriptionSubTitle')}
			</MuiBox>
		</MuiBox>
	);

	function renderDescription(): JSX.Element {
		return (
			<MuiBox className={styles.descriptionContainer}>
				{!isMobile && descriptionTitleContainer}
				<MuiBox className={styles.description}>{t('login.description')}</MuiBox>
			</MuiBox>
		);
	}

	return (
		<PageBodyContent
			fullHeight
			className={styles.container}
			components={[
				isMobile ? descriptionTitleContainer : null,
				!isMobile ? renderDescription() : null,
				<FormCard
					key={'login-form'}
					title={t('login.welcome')}
					className={styles.loginForm}
					content={[
						<Input
							key={'login.email'}
							label={t('login.email')}
							variant="text"
							fullWidth
						/>,
						<Input
							key={'login.password'}
							label={t('login.password')}
							variant="text"
							fullWidth
						/>,
					]}
					footer={[
						<MuiBox key={'login-button'} className={styles.formButtonContainer}>
							<Button
								label={t('login.loginButton')}
								color="primary"
								size="large"
								fullWidth
								loading={loading}
								onClick={() => {
									setLoading(!loading);
									setTimeout(() => {
										navigateTo(PAGES.HOME);
										setLoading(false);
										showSnackbar({ message: t('snackbar.welcome'), variant: 'success' });
									}, 1500);
								}}
							/>
							<BoxSection
								key={66}
								className={styles.formIconButtonsContainer}
								childrenBoxClassName={styles.formIconButtonsContainer}
								components={[
									<IconButton
										key={'login.loginWithFacebook'}
										color="primary"
										size="large"
										variant="fill"
										label={t('login.loginWithFacebook')}
										icon={<FacebookIcon />}
									/>,
									<IconButton
										key={'login.loginWithGoogle'}
										color="primary"
										size="large"
										variant="fill"
										label={t('login.loginWithGoogle')}
										icon={<GoogleIcon />}
									/>,
								]}
							/>
						</MuiBox>,
						<Button
							label={t('login.register')}
							variant="text"
							color="black"
							fullWidth
							key={5666}
							onClick={() => alert('click')}
						/>,
						<LanguageSelector key={5455} color="primary" />,
					]}
				/>,
				isMobile ? renderDescription() : null,
			]}
		/>
	);
}

export default LoginPage;
