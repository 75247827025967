import {
	BaseElementProps,
	BaseOrientation,
	WithFullWidth,
} from '../../base-types/BaseTypes';
import { generateConstructor } from '../../base-types/utils';

export class BoxSectionProps extends BaseElementProps implements WithFullWidth {
	/**
	 * text in section title
	 */
	title?: string;
	/**
	 * element in section title
	 */
	titleElement?: JSX.Element;
	/**
	 * list of box section children
	 */
	components?: JSX.Element[];
	/**
	 * orientation of box section
	 */
	orientation?: BaseOrientation;
	fullWidth?: boolean | undefined;
	childrenBoxClassName?: string;

	constructor(props: BoxSectionProps) {
		super(props);
		generateConstructor(this, props);
		this.orientation = props.orientation || 'horizontal';
	}
}
