import { createInstance } from '../../base-types/utils';
import { getBadge } from '../../small-components/badge/Badge';
import ComponentLoader from '../../small-components/component-loader/ComponentLoader';
import getTooltip from '../../small-components/tooltip/Tooltip';
import styles from './input.module.scss';
import { InputProps } from './types';
import { FormHelperText, InputAdornment, TextField } from '@mui/material';
import classNames from 'classnames';
import React from 'react';

export const Input = (_props: InputProps): JSX.Element => {
	const {
		label,
		color,
		variant,
		size,
		isShadow,
		isWithBadge,
		disabled,
		className,
		fullWidth,
		multiline,
		loading,
		rightIcon,
		leftIcon,
		description,
		sx,
		readOnly,
		onChange,
		...otherProps
	} = createInstance(_props, InputProps);

	const textHelperClasses = classNames(
		color && styles[color],
		styles.textHelper
	);

	const iconClasses = classNames(color && styles[color]);

	const inputClasses = classNames(
		styles.input,
		multiline && styles.multiline,
		disabled && styles.disabled,
		isShadow && styles.shadow,
		size && styles[size],
		className,
		color && styles[color],
		variant && styles[variant],
		loading && styles.loading
	);

	const handleOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		if (onChange) {
			onChange(event.target.value);
		}
	};

	const renderStartIcon = (): JSX.Element | undefined => {
		if (leftIcon) {
			return (
				<InputAdornment className={iconClasses} position="end">
					{leftIcon}
				</InputAdornment>
			);
		}
	};

	const renderEndIcon = (): JSX.Element | undefined => {
		const iconToRender = loading ? (
			<ComponentLoader color={color} variant={variant} />
		) : (
			rightIcon
		);
		if (iconToRender) {
			return (
				<InputAdornment className={iconClasses} position="start">
					{iconToRender}
				</InputAdornment>
			);
		}
	};

	const renderHelperText = (): JSX.Element | undefined => {
		if (label) {
			return (
				<FormHelperText className={textHelperClasses}>{label}</FormHelperText>
			);
		}
	};

	const containerClasses = classNames(
		styles.container,
		fullWidth && styles.fullWidth,
		variant && styles[variant]
	);

	return getBadge({
		fullWidth,
		isWithBadge,
		color,
		component: getTooltip({
			description: description || label,
			component: (
				<div className={containerClasses}>
					{variant === 'text' && renderHelperText()}
					<TextField
						onChange={handleOnChange}
						title={label}
						sx={{
							'& .MuiInputBase-root': {
								borderBottom: 'none',
								cursor: loading ? 'wait' : null,
							},
							'& .MuiInputBase-root:before': {
								borderBottom: 'none !important',
							},
							'& .MuiInputBase-root:after': {
								borderBottom: 'none !important',
							},
							'& .MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before':
								{
									borderBottom: 'none !important',
								},
							'& .MuiInputBase-input': {
								cursor: loading ? 'wait' : null,
							},
							...sx,
						}}
						variant={'standard'}
						fullWidth={fullWidth}
						className={inputClasses}
						disabled={disabled}
						multiline={multiline}
						InputProps={{
							readOnly: readOnly,
							endAdornment: renderEndIcon(),
							startAdornment: renderStartIcon(),
						}}
						{...otherProps}
					/>
					{variant === 'outline' && renderHelperText()}
				</div>
			),
		}),
	});
};
