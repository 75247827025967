import { ToggleDescriptions } from '../base-types/BaseTypes';

export const getToggleDescription = ({
	toggleDescriptions,
	defaultValue,
	toggleState,
}: {
	toggleDescriptions?: ToggleDescriptions;
	defaultValue: string;
	toggleState: boolean;
}): string => {
	if (!toggleDescriptions) {
		return defaultValue;
	}
	if (toggleState) {
		return toggleDescriptions.toFalse;
	}
	return toggleDescriptions.toTrue;
};
