import {
	BaseElementProps,
	WithFullHeight,
} from '../../../../base-types/BaseTypes';
import { generateConstructor } from '../../../../base-types/utils';

export class PageBodyContentProps
	extends BaseElementProps
	implements WithFullHeight
{
	/**
	 * variant of divider
	 */
	components: (JSX.Element | null)[];
	fullHeight?: boolean | undefined;
	constructor(props: PageBodyContentProps) {
		super(props);
		generateConstructor(this, props);
		this.components = props.components || [];
		this.fullHeight = props.fullHeight;
	}
}
