import {
	BaseBackgroundColorProps,
	ScoreStatus,
	ToggleDescriptions,
} from '../../base-types/BaseTypes';
import { generateConstructor } from '../../base-types/utils';

export class ScoreLineProps extends BaseBackgroundColorProps {
	/**
	 * text in section title
	 */
	title?: string;
	/**
	 * is with status button
	 */
	isWithStatusButton?: boolean;
	/**
	 * score line status
	 */
	status?: ScoreStatus;
	/**
	 * element in section title
	 */
	onStatusButtonClick?: () => void;
	/**
	 * score line value
	 */
	value?: string;
	/**
	 * is status approved?
	 */
	isApproved?: boolean;
	/**
	 * is status button loading?
	 */
	isStatusLoading?: boolean;
	/**
	 * description of approved button
	 */
	approvedButtonDescription?: ToggleDescriptions;
	/**
	 * list of box children
	 */
	dropdownElements?: JSX.Element[];
	/**
	 * is to close dropdown?
	 */
	isDropdownFunctionalityDone?: boolean;
	/**
	 * is to close dropdown?
	 */
	isWithNote?: boolean;

	constructor(props: ScoreLineProps) {
		super(props);
		generateConstructor(this, props);
	}
}
