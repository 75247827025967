import { createInstance } from '../../base-types/utils';
import styles from './linear-progress.module.scss';
import { LinearProgressProps } from './types';
import { LinearProgress as MuiLinearProgress } from '@mui/material';
import classNames from 'classnames';
import React from 'react';

/**
 * Primary UI component for user interaction
 */
export const LinearProgress = (_props: LinearProgressProps): JSX.Element => {
	const { className, color, isShow, ...otherProps } = createInstance(
		_props,
		LinearProgressProps
	);

	const classes = classNames(
		styles.linearProgress,
		color && styles[color],
		className
	);
	const barClasses = classNames(
		styles.linearProgress,
		styles.bar,
		color && styles[color]
	);

	React.useEffect(() => {
		const containerElement = document.getElementsByClassName(
			'innerZoomElementWrapper'
		);
		if (containerElement) {
			const node = containerElement[0]?.parentNode;
			if (node instanceof HTMLDivElement) {
				node.style.width = '70%';
			}
		}
	}, []);

	return (
		<>
			{isShow && (
				<MuiLinearProgress
					classes={{ bar: barClasses, root: styles.root }}
					variant="query"
					className={classes}
					{...otherProps}
				/>
			)}
		</>
	);
};
