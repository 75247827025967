import styles from './snackbar.module.scss';
import classNames from 'classnames';
import { getCurrentLanguageSettings } from 'i18n';
import { useSnackbar, OptionsObject, SnackbarKey } from 'notistack';
import React from 'react';
import { IconButton } from 'stories/components/icon-button/IconButton';

// Custom Hook for centralized Snackbar

type SnackbarProps = {
	message: string;
	variant: 'success' | 'error' | 'warning' | 'info';
	options?: OptionsObject;
};
type ShowSnackbarFunction = (props: SnackbarProps) => void;

interface UseCustomSnackbarReturn {
	showSnackbar: ShowSnackbarFunction;
	closeSnackbar: (key?: SnackbarKey) => void;
}

const useSnackbarService = (): UseCustomSnackbarReturn => {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const update = (node: HTMLElement): void => {
		const parent = node.lastChild as HTMLElement;
		if (parent) {
			parent.style.marginLeft = '0px';
			parent.style.marginRight = '0px';
			parent.style.paddingLeft = '0px';
		}
	};

	const showSnackbar = ({ message, options, variant }: SnackbarProps): void => {
		const localVariant = variant === 'info' ? 'primary' : variant;

		enqueueSnackbar(message, {
			variant,
			anchorOrigin: {
				vertical: 'top',
				horizontal: getCurrentLanguageSettings().dir === 'rtl' ? 'left' : 'right',
			},
			autoHideDuration: 3000,
			className: classNames(styles.snackbar, styles[localVariant]),
			onEnter(node) {
				update(node);
			},
			...options,
			action: (key) => (
				<IconButton
					variant={'fill'}
					color={localVariant}
					onClick={() => closeSnackbar(key)}
					label="close"
					action="close"
					size="small"
					id={key.toString()}
				/>
			),
		});
	};
	return { showSnackbar, closeSnackbar };
};

export default useSnackbarService;
