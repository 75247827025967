import { useLocation, useNavigate } from 'react-router-dom';
// import {
// 	IPageNavigation,
// 	pageNavigationConfig,
// } from 'routes/navigation/pagePartsConfiguration';
import {
	IPageNavigation,
	pageNavigationConfig,
	PAGES,
	pagesPath,
} from 'routes/navigation/urls';

interface RouterState {
	navigateTo: (page: PAGES) => void;
	currentPageConfiguration: IPageNavigation;
	currentPage: PAGES;
}

function useRouterService(): RouterState {
	const navigate = useNavigate();
	const location = useLocation();

	// const { id } = useParams();

	function getCurrentPath(): string {
		return location.pathname;
	}

	function getPageDataByPageName(page: PAGES): IPageNavigation {
		return pageNavigationConfig[page];
	}
	function getPageNameByPath(path: string): PAGES {
		const page = (Object.keys(pagesPath) as PAGES[]).find(
			(key) => pagesPath[key] === path
		);
		return page || PAGES.NOT_FOUND;
	}
	function getCurrentPage(): PAGES {
		const currentPath = getCurrentPath();
		const page = getPageNameByPath(currentPath);
		return page;
	}
	const currentPage = getCurrentPage();
	const currentPageConfiguration: IPageNavigation =
		getPageDataByPageName(getCurrentPage());

	const navigateTo = (page: PAGES): void => {
		const pageUrl = pagesPath[page];
		navigate(pageUrl);
	};

	return { navigateTo, currentPageConfiguration, currentPage };
}

export default useRouterService;
