import { PAGES } from '../urls';
// import { getCurrentPage, getPageCurrentData } from '../utils';
import { useTranslation } from 'react-i18next';
import useLayoutService from 'services/layout/LayoutService';
import useRouterService from 'services/router/RouterService';
import PageHeader from 'stories/components/page/page-header/PageHeader';
import { PageHeaderProps } from 'stories/components/page/page-header/types';
import { TabProps } from 'stories/components/tab/types';

export default function PageHeaderComponent(
	props: PageHeaderProps
): JSX.Element {
	const { t } = useTranslation();
	const { currentPage, currentPageConfiguration } = useRouterService();
	const { setSidebarOpenByMobile, sidebarOpenByMobile } = useLayoutService();

	const headerTabs: TabProps[] = [
		{
			label: t('header.home'),
			onClick(): void {
				alert('test');
			},
			selected: currentPage === PAGES.HOME,
		},
		{
			label: t('header.settings'),
			onClick(): void {
				alert('test');
			},
		},
		{
			label: t('header.notifications'),
			onClick(): void {
				alert('test');
			},
		},
	].map((tab, index) => ({
		...tab,
		color: currentPageConfiguration.header.backgroundColor,
		orientation: 'horizontal',
	}));

	return (
		<PageHeader
			{...props}
			tabsProps={headerTabs}
			onMenuClick={() => setSidebarOpenByMobile(!sidebarOpenByMobile)}
		/>
	);
}
