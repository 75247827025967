import { Translation } from './../public/translation';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

type LanguagesSettingsValueType = { title: string; dir: 'rtl' | 'ltr' };
export const languages: Record<string, LanguagesSettingsValueType> = {
	he: { title: 'עברית', dir: 'rtl' },
	en: { title: 'English', dir: 'ltr' },
} as const;

export type LanguageKeys = keyof typeof languages;
i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: 'he',
		supportedLngs: Object.keys(languages),
		debug: true,
		interpolation: {
			escapeValue: false,
		},
		backend: {
			loadPath: '/locales/{{lng}}/{{ns}}.json',
		},
		detection: {
			order: ['cookie', 'cookie', 'querystring', 'navigator', 'htmlTag'],
			caches: ['cookie'],
		},
	})
	.then(() => {
		setDirByCurrentLng();
	});

const setDirByCurrentLng = (): void => {
	const lngDir = getCurrentLanguageSettings().dir;
	document.documentElement.dir = lngDir;
};

export const changeLanguage = (lang: LanguageKeys): void => {
	i18n.changeLanguage(lang);
};

export type TranslationKeys = keyof Translation;

export const getCurrentLanguage = (): string => i18n.language;

export const getCurrentLanguageSettings = (): LanguagesSettingsValueType => {
	return languages[getCurrentLanguage()];
};

i18n.on('languageChanged', (lng) => {
	setDirByCurrentLng();
});

export const onLanguageChanged = (
	event: React.MouseEvent<HTMLElement, MouseEvent>,
	value: string
): void => {
	value = value as LanguageKeys;
	changeLanguage(value);
};

export default i18n;
