import { getCurrentLanguage, languages, onLanguageChanged } from 'i18n';
import { useTranslation } from 'react-i18next';
import { BaseColors } from 'stories/base-types/BaseTypes';
import ToggleGroup from 'stories/components/toggle-button/ToggleGroup';
import { IToggleElementProps } from 'stories/components/toggle-button/types';

export default function LanguageSelector({
	color,
}: {
	color: BaseColors;
}): JSX.Element {
	const { t } = useTranslation();

	const languagesComponents: IToggleElementProps[] = Object.entries(
		languages
	).map(([key, value], index) => ({
		value: key,
		title: value.title,
		description: `${t('system.changeLngTo')} ${value.title}`,
	}));

	return (
		<ToggleGroup
			value={getCurrentLanguage()}
			size={'small'}
			variant="outline"
			color={color}
			orientation="horizontal"
			handleChange={onLanguageChanged}
			components={languagesComponents}
		/>
	);
}
