import { getIconFromMap } from '../../GlobalData';
import { createInstance } from '../../base-types/utils';
import { Button } from '../button/Button';
import styles from './icon-button.module.scss';
import { IconButtonProps } from './types';
import classNames from 'classnames';
import React from 'react';

/**
 * Primary UI component for user interaction
 */
export const IconButton = (_props: IconButtonProps): JSX.Element => {
	const {
		className,
		icon,
		action,
		color,
		variant,
		size,
		sx,
		loading,
		description,
		label,
		...otherProps
	} = createInstance(_props, IconButtonProps);
	const iconClasses = classNames(styles.iconButton, className);

	const localIcon = icon ? icon : action ? getIconFromMap(action) : undefined;

	const renderIcon = (): JSX.Element | undefined => {
		if (localIcon && size === 'small') {
			return React.cloneElement(localIcon, {
				style: {
					maxHeight: '20px',
					maxWidth: '20px',
				},
			});
		}
		return localIcon;
	};

	return (
		<Button
			description={description || label}
			className={iconClasses}
			icon={renderIcon()}
			color={color}
			variant={variant}
			size={size}
			sx={sx}
			label=""
			loading={loading}
			{...otherProps}
		/>
	);
};
