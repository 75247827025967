import { createInstance } from '../../../../base-types/utils';
import styles from './page-body-content.module.scss';
import { PageBodyContentProps } from './types';
import { Box as MuiBox } from '@mui/material';
import classNames from 'classnames';
import React from 'react';

export const PageBodyContent = (_props: PageBodyContentProps): JSX.Element => {
	const { className, components, fullHeight, ...otherProps } = createInstance(
		_props,
		PageBodyContentProps
	);
	const classes = classNames(
		styles.pageBodyContent,
		fullHeight && styles.fullHeight,
		className
	);

	return (
		<MuiBox className={classes} {...otherProps}>
			{components}
		</MuiBox>
	);
};
export default PageBodyContent;
