import HomeRouter from 'routes/pagesRouters/HomeRouter';
import LoginRouter from 'routes/pagesRouters/LoginRouter';
import PageNotFoundRouter from 'routes/pagesRouters/PageNotFoundRouter';
import { PageBodyProps } from 'stories/components/page/page-body/types';
import { PageHeaderProps } from 'stories/components/page/page-header/types';
import { PageSidebarProps } from 'stories/components/page/page-sidebar/types';

export enum PAGES {
	HOME = 'home',
	LOGIN = 'login',
	NOT_FOUND = 'not-found',
}
export const pagesPath: Record<PAGES, string> = {
	[PAGES.HOME]: '/',
	[PAGES.LOGIN]: '/login',
	[PAGES.NOT_FOUND]: '*',
};

export const pageComponentByPath: Record<string, JSX.Element> = {
	[pagesPath[PAGES.HOME]]: <HomeRouter />,
	[pagesPath[PAGES.LOGIN]]: <LoginRouter />,
	[pagesPath[PAGES.NOT_FOUND]]: <PageNotFoundRouter />,
};
export interface IPageNavigation {
	body: PageBodyProps;
	sidebar: PageSidebarProps;
	header: PageHeaderProps;
	showHeader: boolean;
	showSidebar: boolean;
}

const defaultNavigationConfiguration: IPageNavigation = {
	showHeader: true,
	showSidebar: true,
	body: {
		backgroundColor: 'primary',
		colorMode: 'light',
	},
	sidebar: {
		backgroundColor: 'primary',
		colorMode: 'medium',
	},
	header: {
		backgroundColor: 'primary',
		colorMode: 'dark',
	},
};

export const pageNavigationConfig: Record<PAGES, IPageNavigation> = {
	[PAGES.HOME]: {
		...defaultNavigationConfiguration,
		body: {
			backgroundColor: 'primary',
			colorMode: 'light',
		},
		sidebar: {
			backgroundColor: 'primary',
			colorMode: 'medium',
		},
		header: {
			backgroundColor: 'primary',
			colorMode: 'dark',
		},
	},
	[PAGES.LOGIN]: {
		...defaultNavigationConfiguration,
		body: {
			backgroundColor: 'warning',
			colorMode: 'light',
		},
		sidebar: {
			backgroundColor: 'warning',
			colorMode: 'light',
		},
		header: {
			backgroundColor: 'warning',
			colorMode: 'light',
		},
		showHeader: false,
		showSidebar: false,
	},
	[PAGES.NOT_FOUND]: {
		...defaultNavigationConfiguration,
		body: {
			backgroundColor: 'black',
			colorMode: 'dark',
		},
		sidebar: {
			backgroundColor: 'black',
			colorMode: 'medium',
		},
		header: {
			backgroundColor: 'black',
			colorMode: 'medium',
		},

		showHeader: false,
		showSidebar: false,
	},
};
