import { createInstance } from '../../base-types/utils';
import styles from './box-section.module.scss';
import { BoxSectionProps } from './types';
import { Box as MuiBox } from '@mui/material';
import classNames from 'classnames';
import React from 'react';

export const BoxSection = (_props: BoxSectionProps): JSX.Element => {
	const {
		className,
		components,
		title,
		orientation,
		titleElement,
		fullWidth,
		childrenBoxClassName,
		...otherProps
	} = createInstance(_props, BoxSectionProps);
	const classes = classNames(
		styles.boxSection,
		orientation === 'vertical' && styles.vertical,
		fullWidth && styles.fullWidth,
		className
	);
	const titleBoxClasses = classNames(styles.titleBox);
	const childrenBoxClasses = classNames(
		styles.childrenBox,
		childrenBoxClassName
	);
	const titleClasses = classNames(styles.title);

	const titleBox = (
		<MuiBox className={titleBoxClasses}>
			{title && <MuiBox className={titleClasses}>{title}</MuiBox>}
			{titleElement && titleElement}
		</MuiBox>
	);

	return (
		<MuiBox className={classes} {...otherProps}>
			{title && titleBox}
			<MuiBox className={childrenBoxClasses}>{components}</MuiBox>
		</MuiBox>
	);
};
