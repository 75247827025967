import { BaseColorProps, WithFullWidth } from '../../base-types/BaseTypes';
import { generateConstructor } from '../../base-types/utils';

export class FormCardProps extends BaseColorProps implements WithFullWidth {
	// /**
	//  * What variation will the box be from?
	//  */
	// variant?: BaseVariants;
	/**
	 * list of box children
	 */
	title?: string;
	content?: JSX.Element[];
	footer?: JSX.Element[];
	fullWidth?: boolean;

	constructor(props: FormCardProps) {
		super(props);
		generateConstructor(this, props);
	}
}
