import { createInstance } from '../../base-types/utils';
import styles from './list.module.scss';
import { ListProps } from './types';
import { debounce, Grid } from '@mui/material';
import classNames from 'classnames';
import React from 'react';

export const List = (_props: ListProps): JSX.Element => {
	const { className, components, style, backgroundColor, ref, ...otherProps } =
		createInstance(_props, ListProps);
	const classes = classNames(
		styles.list,
		backgroundColor && styles[backgroundColor],
		className
	);
	const [maxHeight, setMaxHeight] = React.useState<number | undefined>();

	const containerStyle: React.CSSProperties = {
		maxHeight: `${maxHeight}px`,
		minHeight: `${maxHeight}px`,
		...style,
	};
	const defaultRef = React.useRef<HTMLDivElement>(null);
	const listRef = ref || defaultRef;

	const renderChildren = (): JSX.Element[] | undefined => {
		if (backgroundColor && components) {
			const copy = [];
			for (let i = 0; i < components.length; i++) {
				copy.push(
					React.cloneElement(components[i], {
						backgroundColor: backgroundColor,
						className: classNames(!maxHeight && styles.hidden),
					})
				);
			}
			return copy;
		}
		return components;
	};

	const updateMaxHeight = React.useCallback((): void => {
		const parseGap = (gap: string | null): number => parseFloat(gap || '0');
		const listPointer = listRef.current;
		if (!listPointer || !components) return;
		const firstChild = listPointer.querySelector(':scope > *');
		if (!(firstChild instanceof Element)) return;
		const { width: childWidth, height: childHeight } =
			firstChild.getBoundingClientRect();
		const { width: listWidth } = listPointer.getBoundingClientRect();
		const listStyles = window.getComputedStyle(listPointer);
		const childrenCounter = components.length;
		const defaultGap = listStyles.gap || '0';
		const columnGap = parseGap(listStyles.columnGap || defaultGap);
		const rowGap = parseGap(listStyles.rowGap || defaultGap);
		const numberOfColumns = Math.floor(listWidth / (childWidth + columnGap)) || 1; // הגנה מפני חלוקה באפס
		const maxChildrenPerColumn = Math.ceil(childrenCounter / numberOfColumns);
		const maxColumnHeight = (childHeight + rowGap) * maxChildrenPerColumn;
		setMaxHeight(maxColumnHeight);
	}, [components, listRef]);

	React.useEffect(() => {
		updateMaxHeight();
	}, [updateMaxHeight]);

	const debouncedUpdateMaxHeight = React.useMemo(
		() => debounce(updateMaxHeight, 150),
		[updateMaxHeight]
	);

	React.useEffect(() => {
		if (typeof window === 'undefined') return;

		window.addEventListener('resize', debouncedUpdateMaxHeight);
		return (): void =>
			window.removeEventListener('resize', debouncedUpdateMaxHeight);
	}, [debouncedUpdateMaxHeight]);

	return (
		<Grid
			container
			style={containerStyle}
			ref={listRef}
			classes={{ root: classes }}
			{...otherProps}
		>
			{renderChildren()}
		</Grid>
	);
};
