import { BaseBackgroundColorProps } from '../../base-types/BaseTypes';
import { generateConstructor } from '../../base-types/utils';
import PageBody from './page-body/PageBody';
import PageHeader from './page-header/PageHeader';
import PageLogo from './page-logo/PageLogo';
import PageSidebar from './page-sidebar/PageSidebar';
import { ReactElement } from 'react';

export class PageProps extends BaseBackgroundColorProps {
	/**
	 * header of the page
	 */
	header?: ReactElement<typeof PageHeader>;
	/**
	 * body of the page
	 */
	body: ReactElement<typeof PageBody>;
	/**
	 * sideBar of the page
	 */
	sidebar?: ReactElement<typeof PageSidebar>;
	logo?: ReactElement<typeof PageLogo>;

	constructor(props: PageProps) {
		super(props);
		generateConstructor(this, props);
		this.body = props.body;
		this.backgroundColor = props.backgroundColor || 'gray';
	}
}
