import { getIconFromMap } from '../../GlobalData';
import {
	BaseInputProps,
	BaseSize,
	BaseVariants,
	InputActionOptionIcons,
	WithBaseSize,
	WithFullWidth,
} from '../../base-types/BaseTypes';
import { generateConstructor, isFunctionExists } from '../../base-types/utils';

export class InputProps
	extends BaseInputProps
	implements WithFullWidth, WithBaseSize
{
	/**
	 * value of the input
	 */
	value?: string | number;
	/**
	 * icon in the left of the button
	 */
	leftIcon?: JSX.Element;
	/**
	 * icon in the right of the button
	 */
	rightIcon?: JSX.Element;
	/**
	 * Will the button expand to the full available width
	 */
	fullWidth?: boolean;
	/**
	 * What variation will the input be from?
	 */
	variant?: Exclude<BaseVariants, 'fill'>;
	/**
	 * Will the input expand to several lines?
	 */
	multiline?: boolean;
	/**
	 * The function of the button - what it activates
	 */
	action?: InputActionOptionIcons;
	/**
	 * Is the input for read only?
	 */
	readOnly?: boolean;
	/**
	 * Which function will be activated when the button is clicked?
	 */
	onChange?: (value: string) => void;
	/**
	 * name of the field - for forms
	 */
	name?: string;
	/**
	 * The text that will be displayed as long as no text has been entered by the user
	 */
	placeholder?: string;

	size?: BaseSize | undefined;

	constructor(props: InputProps) {
		super(props);
		generateConstructor(this, props);
		this.variant = props.variant || 'outline';
		this.leftIcon = props.action ? getIconFromMap(props.action) : props.leftIcon;
		this.rightIcon = props.rightIcon;
		this.readOnly = getIsReadOnly(props);
	}
}

const getIsReadOnly = (props: InputProps): boolean | undefined => {
	if (props.disabled || !isFunctionExists(props.onChange) || props.loading) {
		return true;
	}
	return props.readOnly;
};
