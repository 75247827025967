import { getIconFromMap, getStatusColor } from '../../GlobalData';
import { createInstance } from '../../base-types/utils';
import getTooltip from '../../small-components/tooltip/Tooltip';
import { getToggleDescription } from '../../utils/ComponentsUtils';
import { Button } from '../button/Button';
import Divider from '../divider/Divider';
import { IconButton } from '../icon-button/IconButton';
import styles from './score-line.module.scss';
import { ScoreLineProps } from './types';
import { Box as MuiBox } from '@mui/material';
import classNames from 'classnames';
import React from 'react';

export const ScoreLine = (_props: ScoreLineProps): JSX.Element => {
	const {
		className,
		title,
		value,
		style,
		status,
		isApproved,
		isStatusLoading,
		isWithStatusButton,
		dropdownElements,
		backgroundColor,
		approvedButtonDescription,
		isDropdownFunctionalityDone,
		isWithNote,
		onStatusButtonClick,
		...otherProps
	} = createInstance(_props, ScoreLineProps);
	const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
	const [isDropdownVisible, setIsDropdownVisible] = React.useState(false);

	const transitionDuration = 320;

	const scoreLineWidthDropdown = classNames(
		styles.scoreLineWithDropdown,
		isDropdownOpen && styles.isDropdownOpen,
		isDropdownVisible && styles.isDropdownVisible,
		backgroundColor && styles[backgroundColor]
	);

	const classes = classNames(
		styles.scoreLine,
		isDropdownOpen && styles.isDropdownOpen,
		className
	);
	const sectionClasses = classNames(styles.scoreLineSection);
	const actionSectionClasses = classNames(sectionClasses, styles.actionSection);
	const dropdownElementsBoxClasses = classNames(
		isDropdownOpen && styles.open,
		!isDropdownOpen && styles.close,
		styles.elementsContainer
	);

	const handleOnStatusButtonClick: () => void = () => {
		if (onStatusButtonClick) {
			onStatusButtonClick();
		}
	};

	const disabledDropdown = !dropdownElements || dropdownElements.length === 0;

	const handleDropdownClick: () => void = React.useCallback(() => {
		if (!disabledDropdown) {
			setIsDropdownOpen(!isDropdownOpen);
			if (!isDropdownOpen) {
				setIsDropdownVisible(true);
			} else {
				setTimeout(() => {
					setIsDropdownVisible(false);
				}, transitionDuration);
			}
		}
	}, [disabledDropdown, isDropdownOpen]);

	React.useEffect(() => {
		if (isDropdownFunctionalityDone && isDropdownOpen) {
			handleDropdownClick();
		}
	}, [isDropdownFunctionalityDone, handleDropdownClick, isDropdownOpen]);

	const renderDropdownElements = (): JSX.Element => {
		return (
			<MuiBox className={styles.dropdownElementsContainer}>
				<Divider
					thickness="normal"
					color={backgroundColor}
					className={styles.divider}
					borderTransparency="min"
				/>
				{dropdownElements}
			</MuiBox>
		);
	};

	const getStatusTitle = (): string => {
		switch (status) {
			case 'excellent':
				return 'מצויין';
			case 'good':
				return 'טוב';
			case 'unsatisfactory':
				return 'טעון שיפור';
			case 'bad':
				return 'לא טוב';
			default:
				return '';
		}
	};

	const dropdown = (
		<MuiBox
			className={dropdownElementsBoxClasses}
			style={{ transitionDuration: `${transitionDuration / 1000}s` }}
		>
			{renderDropdownElements()}
		</MuiBox>
	);

	const dropdownButton = (
		<IconButton
			disabled={disabledDropdown}
			label={''}
			isWithBadge={isWithNote}
			size="small"
			variant="outline"
			color="black"
			description={disabledDropdown ? undefined : isDropdownOpen ? 'מזער' : 'הרחב'}
			action={isDropdownOpen ? 'dropup' : 'dropdown'}
			onClick={handleDropdownClick}
		/>
	);

	const statusButton = (
		<IconButton
			description={getToggleDescription({
				defaultValue: '',
				toggleState: !!isApproved,
				toggleDescriptions: approvedButtonDescription,
			})}
			size="small"
			loading={isStatusLoading}
			label={getStatusTitle()}
			color={getStatusColor(status)}
			icon={isApproved ? getIconFromMap('v') : undefined}
			onClick={onStatusButtonClick ? handleOnStatusButtonClick : undefined}
			variant="fill"
		/>
	);

	const titleBox = getTooltip({
		description: title,
		component: <MuiBox className={styles.title}>{title}</MuiBox>,
	});

	const valueButton = (
		<Button
			label={value || ''}
			isShadow
			color="black"
			size="small"
			variant="outline"
		/>
	);
	return (
		<MuiBox className={styles.container}>
			<MuiBox className={styles.scoreLineContainer} style={style}>
				<MuiBox className={scoreLineWidthDropdown}>
					<MuiBox className={classes} {...otherProps}>
						<MuiBox className={sectionClasses}>
							{isWithStatusButton && statusButton}
							{title && titleBox}
						</MuiBox>
						<MuiBox className={actionSectionClasses}>
							{valueButton}
							{dropdownButton}
						</MuiBox>
					</MuiBox>
					{dropdown}
				</MuiBox>
			</MuiBox>
		</MuiBox>
	);
};
