import { BasePosition } from '../../base-types/BaseTypes';
import { createInstance } from '../../base-types/utils';
import styles from './divider.module.scss';
import { DividerProps } from './types';
import { Divider as MuiDivider } from '@mui/material';
import classNames from 'classnames';
import React from 'react';

export const Divider = (_props: DividerProps): JSX.Element => {
	const {
		className,
		color,
		variant,
		orientation,
		thickness,
		text,
		textPosition,
		borderTransparency,
		isTextHighlighted,
		...otherProps
	} = createInstance(_props, DividerProps);

	const dividerClasses = classNames(
		styles.divider,
		color && styles[color],
		!text && thickness && styles[thickness],
		!text && styles.withoutValue,
		borderTransparency && styles[borderTransparency],
		className
	);
	const rootClasses = classNames(
		borderTransparency && styles[borderTransparency],
		styles.dividerRoot,
		thickness && styles[thickness],
		color && styles[color],
		isTextHighlighted && styles.boldText
	);

	const getTextPosition = (): BasePosition => {
		switch (textPosition) {
			case 'left':
				return 'right';
			case 'right':
				return 'left';
			default:
				return 'center';
		}
	};

	return (
		<MuiDivider
			textAlign={getTextPosition()}
			classes={{ root: rootClasses }}
			orientation={orientation}
			variant={variant}
			flexItem={true}
			className={dividerClasses}
			{...otherProps}
		>
			{text}
		</MuiDivider>
	);
};
export default Divider;
