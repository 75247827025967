import {
	BaseColorProps,
	BaseOrientation,
	BaseVariants,
	BaseSize,
	WithFullWidth,
} from '../../base-types/BaseTypes';
import { generateConstructor } from '../../base-types/utils';

export interface IToggleElementProps {
	value: string | number;
	title?: string | number;
	icon?: JSX.Element;
	description?: string;
}

export class ToggleGroupProps extends BaseColorProps implements WithFullWidth {
	/**
	 * orientation of the divider
	 */
	orientation?: BaseOrientation;
	handleChange: (event: React.MouseEvent<HTMLElement>, nextView: string) => void;
	components: IToggleElementProps[];
	variant?: BaseVariants;
	size?: BaseSize;
	value: string | number;
	fullWidth?: boolean | undefined;

	constructor(props: ToggleGroupProps) {
		super(props);
		generateConstructor(this, props);
		this.orientation = props.orientation || 'vertical';
		this.handleChange = props.handleChange;
		this.components = props.components;
		this.variant = props.variant || 'outline';
		this.value = props.value;
	}
}
