import {
	BaseCallbackProps,
	BaseColorMode,
	BaseColors,
	BaseOrientation,
	WithColorMode,
} from '../../base-types/BaseTypes';
import { generateConstructor } from '../../base-types/utils';

export class TabProps extends BaseCallbackProps implements WithColorMode {
	/**
	 * is tab disabled?
	 */
	disabled?: boolean;
	/**
	 * on tab click
	 */
	onClick?: () => void;
	/**
	 * is tab selected?
	 */
	selected?: boolean;
	/**
	 * is tabs title tab?
	 */
	isTabsTitle?: boolean;
	/**
	 * orientation of tab
	 */
	orientation?: BaseOrientation;

	color?: BaseColors | undefined;

	colorMode?: BaseColorMode;
	constructor(props: TabProps) {
		super(props);
		generateConstructor(this, props);
		this.orientation = props.orientation || 'horizontal';
		this.colorMode = props.colorMode || 'dark';
		this.color = props.color || 'black';
	}
}
