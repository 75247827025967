import { BaseButtonProps, WithFullWidth } from '../../base-types/BaseTypes';
import { generateConstructor } from '../../base-types/utils';
import { JSX } from 'react/jsx-runtime';

export class ButtonProps extends BaseButtonProps implements WithFullWidth {
	/**
	 * icon in the left of the button
	 */
	endIcon?: JSX.Element;
	/**
	 * icon in the right of the button
	 */
	startIcon?: JSX.Element;
	/**
	 * Will the button expand to the full available width
	 */
	fullWidth?: boolean;

	constructor(props: ButtonProps) {
		super(props);
		generateConstructor(this, props);
	}
}
