import { createInstance } from '../../base-types/utils';
import { getBadge } from '../../small-components/badge/Badge';
import ComponentLoader from '../../small-components/component-loader/ComponentLoader';
import getTooltip from '../../small-components/tooltip/Tooltip';
import styles from './button.module.scss';
import { ButtonProps } from './types';
import { Button as MuiButton } from '@mui/material';
import classNames from 'classnames';
import React from 'react';

export const Button = (props: ButtonProps): JSX.Element => {
	const {
		variant,
		size,
		label,
		className,
		loading,
		color,
		isShadow,
		disabled,
		isWithBadge,
		isSelected,
		icon,
		endIcon,
		startIcon,
		fullWidth,
		isClickable,
		description,
		...otherProps
	} = createInstance(props, ButtonProps);

	const iconClasses = classNames(
		styles.icon,
		color && styles[color],
		variant && styles[variant]
	);

	const sideIconClasses = classNames(styles.button, styles.noMargin);

	const clonedSideIconClasses = classNames(
		styles.sideIcon,
		styles.buttonSiteIcon
	);

	const clonedStartIconClassNames = classNames(
		clonedSideIconClasses,
		styles.startIcon
	);

	const clonedEndIconClassNames = classNames(
		clonedSideIconClasses,
		styles.endIcon
	);

	const renderChildren = (): JSX.Element | string => {
		if (icon) {
			return React.cloneElement(icon, { className: iconClasses });
		}
		return label;
	};

	const renderStartIcon = (): JSX.Element | undefined => {
		if (!loading && startIcon) {
			return React.cloneElement(startIcon, {
				className: clonedStartIconClassNames,
			});
		}
	};

	const renderEndIcon = (): JSX.Element | undefined => {
		if (!loading && endIcon) {
			return React.cloneElement(endIcon, {
				className: clonedEndIconClassNames,
			});
			// return endIcon;
		}
	};

	const buttonClasses = classNames(
		styles.button,
		icon && styles.withIcon,
		isSelected && styles.selected,
		disabled && styles.disabled,
		isShadow && styles.shadow,
		size && styles[size],
		isClickable && styles.isClickable,
		color && styles[color],
		variant && styles[variant],
		fullWidth && styles.fullWidth,
		loading && styles.loading,
		!icon && !label && styles.emptyElement,
		renderEndIcon() && styles.withEndIcon,
		renderStartIcon() && styles.withStartIcon,
		className
	);
	const contentClasses = classNames(
		styles.content,
		icon && !label && styles.iconOnly,
		size && styles[`${size}-content`]
	);
	return getBadge({
		color,
		fullWidth,
		isWithBadge,
		component: getTooltip({
			description: description || label,
			component: (
				<MuiButton
					fullWidth={fullWidth}
					endIcon={renderEndIcon()}
					startIcon={renderStartIcon()}
					{...otherProps}
					classes={{
						root: buttonClasses,
						endIcon: sideIconClasses,
						startIcon: sideIconClasses,
					}}
					disableRipple={!isClickable}
				>
					<>
						{(icon || label || loading) && (
							<div className={contentClasses}>
								{loading && (
									<ComponentLoader
										className={iconClasses}
										color={color}
										variant={variant}
									/>
								)}
								{!loading && renderChildren()}
							</div>
						)}
					</>
				</MuiButton>
			),
		}),
	});
};
