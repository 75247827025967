import './index.css';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { LayoutServiceProvider } from 'services/layout/LayoutService';

const AppProviders = ({ children }: { children: JSX.Element }): JSX.Element => {
	return (
		<React.StrictMode>
			<BrowserRouter>
				<LayoutServiceProvider>
					<SnackbarProvider maxSnack={3}>{children}</SnackbarProvider>
				</LayoutServiceProvider>
			</BrowserRouter>
		</React.StrictMode>
	);
};
export default AppProviders;
