import { createInstance } from '../../../base-types/utils';
import styles from './page-logo.module.scss';
import { PageLogoProps } from './types';
import { ReactComponent as LogoIcon } from '@assets/talmid-plus-icon.svg';
import { Box as MuiBox } from '@mui/material';
import classNames from 'classnames';
import React from 'react';

export const PageLogo = (_props: PageLogoProps): JSX.Element => {
	const { className, backgroundColor, colorMode, ...otherProps } =
		createInstance(_props, PageLogoProps);

	const classes = classNames(
		styles.pageLogo,
		backgroundColor && styles[backgroundColor],
		colorMode && styles[colorMode],
		className
	);

	const logoClasses = classNames(styles.logo);

	return (
		<MuiBox className={classes} {...otherProps}>
			<LogoIcon className={logoClasses} />
		</MuiBox>
	);
};
export default PageLogo;
