import {
	BaseColorProps,
	BaseTransparency,
	BaseThicknesses,
	BaseVariants,
	BaseOrientation,
	WithFullWidth,
} from '../../base-types/BaseTypes';
import { generateConstructor } from '../../base-types/utils';

export class BoxProps extends BaseColorProps implements WithFullWidth {
	/**
	 * What variation will the box be from?
	 */
	variant?: BaseVariants;
	/**
	 * list of box children
	 */
	components?: JSX.Element[];
	/**
	 * Highlighted of the border
	 */
	borderTransparency?: BaseTransparency;
	/**
	 * thickness of the border
	 */
	thickness?: BaseThicknesses;
	/**
	 * orientation of box
	 */
	orientation?: BaseOrientation;
	fullWidth?: boolean | undefined;

	constructor(props: BoxProps) {
		super(props);
		generateConstructor(this, props);
		this.variant = props.variant || 'outline';
		this.borderTransparency = props.borderTransparency || 'medium';
		this.thickness = props.thickness || 'normal';
		this.orientation = props.orientation || 'vertical';
		this.fullWidth = props.fullWidth || true;
	}
}
