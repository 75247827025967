import { actionsIcons } from '../../GlobalData';
import { createInstance, isFunctionExists } from '../../base-types/utils';
import { Button } from '../button/Button';
import styles from './chip.module.scss';
import { ChipProps } from './types';
import { IconButton } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { JSX } from 'react/jsx-runtime';

/**
 * Primary UI component for user interaction
 */
export const Chip = (_props: ChipProps): JSX.Element => {
	const {
		className,
		onEndIconClick,
		onStartIconClick,
		startIcon,
		endIcon,
		size,
		action,
		disabled,
		...otherProps
	} = createInstance(_props, ChipProps);

	const classes = classNames(styles.iconButton, className);

	const activeIconClasses = classNames(styles.chip, styles.buttonSiteIcon);

	const iconButtonClasses = classNames(
		styles.iconButton,
		size === 'small' && styles.smallIconButton
	);
	const smallSx =
		size === 'small'
			? { maxHeight: '20px', maxWidth: '20px', minHeight: '0', minWidth: '0' }
			: {};

	const renderStartIcon = (): JSX.Element | undefined => {
		if (onStartIconClick) {
			if (action) {
				return getClickableChipIcon(actionsIcons[action], onStartIconClick);
			} else if (startIcon) {
				return React.cloneElement(startIcon, {
					className: iconButtonClasses,
					style: smallSx,
				});
			}
		}
	};
	const renderEndIcon = (): JSX.Element | undefined => {
		if (onEndIconClick && endIcon) {
			return getClickableChipIcon(endIcon, onEndIconClick);
		}
	};

	const getClickableChipIcon = (
		element: JSX.Element,
		onClick: (e?: any) => any
	): JSX.Element => {
		return (
			<IconButton
				style={smallSx}
				sx={{
					padding: 0,
				}}
				className={iconButtonClasses}
				size="small"
				onClick={(e) => {
					e.stopPropagation();
					onClick(e);
				}}
				disabled={disabled || !onClick}
				disableRipple={!isFunctionExists(onClick)}
			>
				{React.cloneElement(element as any, {
					className: activeIconClasses,
				})}
			</IconButton>
		);
	};

	return (
		<Button
			sx={{
				'& .MuiButton-icon': {
					margin: 'none !important',
				},
			}}
			size={size}
			disabled={disabled}
			endIcon={renderEndIcon()}
			startIcon={renderStartIcon()}
			className={classes}
			{...otherProps}
		/>
	);
};
