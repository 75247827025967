import {
	BaseColors,
	IconActionOptions,
	ScoreStatus,
} from './base-types/BaseTypes';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/Error';
import ArrowDropDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import SearchIcon from '@mui/icons-material/Search';
import React from 'react';
import { JSX } from 'react/jsx-runtime';

export const actionsIcons: {
	[key in IconActionOptions]: JSX.Element;
} = {
	dropdown: <ArrowDropDownIcon />,
	dropup: <KeyboardArrowUpIcon />,
	close: <CloseOutlinedIcon />,
	minus: <RemoveOutlinedIcon />,
	plus: <AddOutlinedIcon />,
	search: <SearchIcon />,
	v: <DoneIcon />,
	error: <ErrorIcon />,
	success: <CheckCircleIcon />,
	menuOpen: <MenuOpenIcon />,
};

export const getIconFromMap = (action: IconActionOptions): JSX.Element => {
	return actionsIcons[action];
};

export const getStatusColor = (status?: ScoreStatus): BaseColors => {
	switch (status) {
		case 'excellent':
			return 'success';
		case 'bad':
			return 'error';
		case 'good':
			return 'primary';
		case 'unsatisfactory':
			return 'secondary';
		default:
			return 'gray';
	}
};
