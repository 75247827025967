import {
	BaseBackgroundColorProps,
	BaseColorMode,
	WithColorMode,
} from '../../../base-types/BaseTypes';
import { generateConstructor } from '../../../base-types/utils';
import { TabProps } from '../../tab/types';

export class PageSidebarProps
	extends BaseBackgroundColorProps
	implements WithColorMode
{
	/**
	 * tab in page header
	 */
	tabsProps?: TabProps[];
	colorMode?: BaseColorMode;
	onCloseByMobile?: () => void;
	isOpenByMobile?: boolean;
	constructor(props: PageSidebarProps) {
		super(props);
		generateConstructor(this, props);
		this.colorMode = props.colorMode || 'medium';
		this.backgroundColor = props.backgroundColor || 'gray';
		this.onCloseByMobile = props.onCloseByMobile;
		this.isOpenByMobile = props.isOpenByMobile;
	}
}
