import { BaseColorProps, BaseColors } from '../../base-types/BaseTypes';
import { generateConstructor } from '../../base-types/utils';

export class LinearProgressProps extends BaseColorProps {
	/**
	 * is the linear progress activated?
	 */
	isShow?: boolean;
	/**
	 * What color should the element be?
	 */
	color?: Exclude<BaseColors, 'white'>;

	constructor(props: LinearProgressProps) {
		super(props);
		generateConstructor(this, props);
	}
}
