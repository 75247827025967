import { PAGES } from 'routes/navigation/urls';
import useLayoutService from 'services/layout/LayoutService';
import useRouterService from 'services/router/RouterService';
import useScreenService from 'services/screen/ScreenService';
import useCustomSnackbarService from 'services/snackbar/SnackbarService';
import { BoxSection } from 'stories/components/box-section/BoxSection';
import { Box } from 'stories/components/box/Box';
import { Button } from 'stories/components/button/Button';
import { Chip } from 'stories/components/chip/Chip';
import Divider from 'stories/components/divider/Divider';
import { IconButton } from 'stories/components/icon-button/IconButton';
import { List } from 'stories/components/list/List';
// import { List } from 'stories/components/list/List';
import PageBodyContent from 'stories/components/page/page-body/page-body-content/PageBodyContent';
import { getScoreLinesWithStates } from 'stories/utils/StoriesUtils';

// import { getScoreLinesWithStates } from 'stories/utils/StoriesUtils';

function HomePage(): JSX.Element {
	const { showSnackbar } = useCustomSnackbarService();
	const { navigateTo } = useRouterService();
	const { setLoading, loading } = useLayoutService();
	const { isDesktop } = useScreenService();

	const orientation = isDesktop ? 'vertical' : 'horizontal';

	return (
		<PageBodyContent
			components={[
				<Box
					key={111}
					color="primary"
					variant="outline"
					orientation="horizontal"
					components={[
						<BoxSection
							key={1}
							orientation="horizontal"
							title="תצוגת snacbar לפי סוג"
							components={[
								<Button
									key={99876}
									fullWidth
									size="large"
									color="white"
									label="בדיקת מעבר דף"
									onClick={() => {
										setLoading(!loading);
										setTimeout(() => {
											navigateTo(PAGES.LOGIN);
											setLoading(false);
										}, 1500);
									}}
								/>,

								<Button
									key={9}
									fullWidth
									size="large"
									color="primary"
									label="מידע"
									onClick={() => {
										showSnackbar({
											message: 'הודעת מידע',
											variant: 'info',
										});
									}}
								/>,

								<Button
									key={9}
									fullWidth
									label="שגיאה"
									size="large"
									color="error"
									onClick={() => {
										showSnackbar({
											message: 'הודעת שגיאה',
											variant: 'error',
										});
									}}
								/>,
								<Button
									key={9}
									fullWidth
									label="הצלחה"
									size="large"
									color="success"
									onClick={() => {
										showSnackbar({
											message: 'הודעת הצלחה',
											variant: 'success',
										});
									}}
								/>,
								<Button
									key={9}
									fullWidth
									label="התראה"
									size="large"
									color="warning"
									onClick={() => {
										showSnackbar({
											message: 'הודעת התראה',
											variant: 'warning',
										});
									}}
								/>,
							]}
						/>,
					]}
				/>,
				<Divider
					color="primary"
					key={2}
					text="קטע ראשון"
					orientation={'horizontal'}
				/>,
				<Box
					key={3}
					color="primary"
					variant="outline"
					orientation={orientation}
					components={[
						<BoxSection
							key={1}
							title="קטע ראשון"
							orientation={orientation}
							titleElement={
								<Chip
									label="ממוצע: 95%"
									variant="fill"
									startIcon={<IconButton label="" color="success" size="small" />}
									onStartIconClick={() => null}
								/>
							}
							components={[
								<Chip
									key={1}
									label="ציפ ראשון"
									action="close"
									onStartIconClick={() => alert('dropdown')}
								/>,
								<Chip
									key={2}
									label="צים שני"
									action="minus"
									onStartIconClick={() => alert('dropdown')}
								/>,
								<Chip
									key={3}
									label="צים שלישי"
									action="plus"
									onStartIconClick={() => alert('dropdown')}
								/>,
								<Chip
									key={4}
									label="ציפ ראשון"
									action="close"
									onStartIconClick={() => alert('dropdown')}
									isSelected
								/>,
								<Chip
									key={5}
									label="ציפ ראשון"
									action="close"
									onStartIconClick={() => alert('dropdown')}
								/>,
								<Chip
									key={6}
									label="ציפ ראשון"
									action="close"
									onStartIconClick={() => alert('dropdown')}
								/>,
							]}
						/>,
						<BoxSection
							title="קטע שני"
							key={2}
							orientation={orientation}
							components={[
								<Chip
									key={7}
									label="צים שני"
									action="minus"
									onStartIconClick={() => alert('dropdown')}
								/>,
								<Chip
									key={8}
									label="צים שני"
									action="minus"
									onStartIconClick={() => alert('dropdown')}
									isSelected
								/>,
								<Chip
									key={9}
									label="צים שלישי"
									action="plus"
									onStartIconClick={() => alert('dropdown')}
								/>,
							]}
						/>,
					]}
				/>,
				<Divider
					color="primary"
					key={2}
					text="קטע ראשון"
					orientation={'horizontal'}
				/>,
				<List
					key={3}
					backgroundColor="primary"
					components={getScoreLinesWithStates(15)}
				/>,
			]}
		/>
	);
}

export default HomePage;
