import { getIconFromMap } from '../../GlobalData';
import {
	BaseButtonProps,
	ButtonActionOptionIcons,
} from '../../base-types/BaseTypes';
import { generateConstructor } from '../../base-types/utils';

export class IconButtonProps extends BaseButtonProps {
	/**
	 * icon in the center of the button
	 */
	icon?: JSX.Element;
	/**
	 * The function of the button - what it activates
	 */
	action?: ButtonActionOptionIcons;

	constructor(props: IconButtonProps) {
		super(props);
		generateConstructor(this, props);
		this.icon = getIconButtonIcon(props);
		this.variant = props.variant
			? props.variant
			: props.icon
				? 'outline'
				: 'fill';
	}
}

const getIconButtonIcon = (props: IconButtonProps): JSX.Element | undefined => {
	return props?.action ? getIconFromMap(props.action) : props.icon;
};
