import {
	BaseBackgroundColorProps,
	BaseColorMode,
	BaseColors,
	WithLoading,
} from '../../../base-types/BaseTypes';
import { generateConstructor } from '../../../base-types/utils';
import PageBodyContent from './page-body-content/PageBodyContent';
import { ReactElement } from 'react';
import { BrowserRouter } from 'react-router-dom';

export class PageBodyProps
	extends BaseBackgroundColorProps
	implements WithLoading
{
	/**
	 * variant of divider
	 */
	content?:
		| ReactElement<typeof PageBodyContent>
		| ReactElement<typeof BrowserRouter>;
	colorMode?: BaseColorMode;

	// components: JSX.Element[];

	loading?: boolean | undefined;

	loaderColor?: Exclude<BaseColors, 'white'>;

	constructor(props: PageBodyProps) {
		super(props);
		generateConstructor(this, props);
		const finalColor = props.backgroundColor || 'gray';
		this.backgroundColor = finalColor;
		this.loaderColor =
			finalColor !== 'white' ? finalColor || 'primary' : 'primary';
	}
}
