import { createInstance } from '../../base-types/utils';
import Divider from '../divider/Divider';
import styles from './box.module.scss';
import { BoxProps } from './types';
import { Box as MuiBox } from '@mui/material';
import classNames from 'classnames';
import React from 'react';

export const Box = (_props: BoxProps): JSX.Element => {
	const {
		className,
		color,
		variant,
		components,
		borderTransparency,
		orientation,
		thickness,
		fullWidth,
		...otherProps
	} = createInstance(_props, BoxProps);

	const classes = classNames(
		styles.box,
		color && styles[color],
		variant && styles[variant],
		orientation && styles[orientation],
		borderTransparency && styles[`border-${borderTransparency}`],
		thickness && styles[thickness],
		fullWidth && styles.fullWidth,
		className
	);

	const dividerComponent = (
		<Divider
			orientation="vertical"
			variant="fullWidth"
			thickness={thickness}
			borderTransparency={borderTransparency}
			color={color}
		/>
	);

	const renderChildren = (): JSX.Element[] | undefined => {
		if (components) {
			return components.map((child, index) => {
				return (
					<>
						{React.cloneElement(child, { key: index })}
						{index < components.length - 1 && dividerComponent}
					</>
				);
			});
		}
	};

	return (
		<MuiBox className={classes} {...otherProps}>
			{renderChildren()}
		</MuiBox>
	);
};
