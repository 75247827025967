import { createInstance } from '../../base-types/utils';
import styles from './component-loader.module.scss';
import { ComponentLoaderProps } from './types';
import { ReactComponent as ComponentLoaderIcon } from '@assets/icon/tadpole.svg';
import classNames from 'classnames';
import React from 'react';

const ComponentLoader = (_props: ComponentLoaderProps): JSX.Element => {
	const { className, color, variant, ...otherProps } = createInstance(
		_props,
		ComponentLoaderProps
	);

	const getColorStyle = (): string | undefined => {
		if (color && variant !== 'fill') {
			return styles[color];
		}
	};

	const classes = classNames(className, getColorStyle(), styles.componentLoader);
	const Loader = (): JSX.Element => (
		<ComponentLoaderIcon className={classes} {...otherProps} />
	);
	return <Loader />;
};
export default ComponentLoader;
