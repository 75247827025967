import { BaseColorProps, WithFullWidth } from '../../base-types/BaseTypes';
import { generateConstructor } from '../../base-types/utils';

export class BadgeProps extends BaseColorProps implements WithFullWidth {
	/**
	 * variant of the loader
	 */
	component?: JSX.Element;
	/**
	 * Will the badge expand to the full available width
	 */
	fullWidth?: boolean;

	constructor(props: BadgeProps) {
		// super()
		// generateConstructor(this, props)
		super(props);
		generateConstructor(this, props);
	}
}
