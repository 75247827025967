import { useTranslation } from 'react-i18next';
import useLayoutService from 'services/layout/LayoutService';
import useRouterService from 'services/router/RouterService';
import PageSidebar from 'stories/components/page/page-sidebar/PageSidebar';
import { PageSidebarProps } from 'stories/components/page/page-sidebar/types';
import { TabProps } from 'stories/components/tab/types';

export default function PageSidebarComponent(
	props: PageSidebarProps
): JSX.Element {
	const { t } = useTranslation();

	const { currentPageConfiguration } = useRouterService();
	const { sidebarOpenByMobile, setSidebarOpenByMobile } = useLayoutService();

	const sidebarTabs: TabProps[] = [
		{
			label: t('sidebar.personalCard'),
			isTabsTitle: true,
			onClick(): void {
				alert('test');
			},
		},
		{
			label: t('sidebar.presence'),
			onClick(): void {
				alert('test');
			},
		},
		{
			label: t('sidebar.hours'),
			onClick(): void {
				alert('test');
			},
		},
		{
			label: t('sidebar.tests'),
			onClick(): void {
				alert('test');
			},
		},
		{
			label: t('sidebar.studentCard'),
			isTabsTitle: true,
			onClick(): void {
				alert('test');
			},
		},
		{
			label: t('sidebar.presence'),
			onClick(): void {
				alert('test');
			},
		},
		{
			label: t('sidebar.extras'),
			onClick(): void {
				alert('test');
			},
		},
		{
			label: t('sidebar.tests'),
			onClick(): void {
				alert('test');
			},
		},
	].map((tab, index) => ({
		...tab,
		color: currentPageConfiguration.header.backgroundColor,
		orientation: 'vertical',
	}));

	return (
		<PageSidebar
			{...props}
			tabsProps={sidebarTabs}
			isOpenByMobile={sidebarOpenByMobile}
			onCloseByMobile={() => setSidebarOpenByMobile(false)}
		/>
	);
}
