import { BaseColors } from '../../base-types/BaseTypes';
import { createInstance } from '../../base-types/utils';
import styles from './badge.module.scss';
import { BadgeProps } from './types';
import { Badge as MuiBadge } from '@mui/material';
import classNames from 'classnames';
import React from 'react';

const Badge = (_props: BadgeProps): JSX.Element => {
	const { className, component, fullWidth, color, ...otherProps } =
		createInstance(_props, BadgeProps);

	const convertColor: BaseColors | undefined =
		color && ['white', 'gray'].includes(color) ? 'black' : color;

	const badgeClasses = classNames(
		className,
		styles.customClassNames,
		convertColor && styles[convertColor],
		styles.badge
	);

	const test = classNames(styles.container, fullWidth && styles.fullWidth);
	return (
		<MuiBadge
			anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
			classes={{ badge: badgeClasses, root: test }}
			variant="dot"
			sx={{
				'& .css-1c32n2y-MuiBadge-root': {
					width: '100%',
				},
			}}
			{...otherProps}
		>
			{component}
		</MuiBadge>
	);
};
export default Badge;

export const getBadge = ({
	component,
	color,
	fullWidth,
	isWithBadge,
}: {
	component: JSX.Element;
	isWithBadge?: boolean;
	color: any;
	fullWidth?: boolean;
}): JSX.Element => {
	if (isWithBadge) {
		return <Badge component={component} color={color} fullWidth={fullWidth} />;
	}
	return component;
};
