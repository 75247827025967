import { generateConstructor, getIsButtonClickable } from './utils';
import { SxProps, Theme } from '@mui/material';
import { JSX } from 'react/jsx-runtime';

export type ButtonActionOptionIcons =
	| 'close'
	| 'dropdown'
	| 'dropup'
	| 'plus'
	| 'minus'
	| 'v'
	| 'menuOpen';

export type InputActionOptionIcons = 'search';
export type GeneralOptionIcons = 'error' | 'success';

export type IconActionOptions =
	| ButtonActionOptionIcons
	| GeneralOptionIcons
	| InputActionOptionIcons;

export type BaseVariants = 'outline' | 'text' | 'fill';

export type BaseLightColors =
	| 'intermediate'
	| 'secondary'
	| 'success'
	| 'white'
	| 'gray';

export type BaseDarksColors =
	| 'intermediate'
	| 'primary'
	| 'intermediate'
	| 'error'
	| 'warning'
	| 'black';

export type BaseColors = BaseLightColors | BaseDarksColors;

export type ScoreStatus = 'excellent' | 'good' | 'unsatisfactory' | 'bad';

export type BaseThicknesses = 'thick' | 'normal' | 'thin';

export type BasePosition = 'center' | 'right' | 'left';

export type BaseTransparency = 'min' | 'low' | 'medium' | 'high';

export type BaseOrientation = 'vertical' | 'horizontal';
export type BaseSize = 'small' | 'medium' | 'large';
export type BaseColorMode = 'dark' | 'medium' | 'light';

export type ToggleDescriptions = {
	toTrue: string;
	toFalse: string;
};

export interface WithBaseVariants {
	variant?: BaseVariants;
}
export interface WithBaseSize {
	size?: BaseSize;
}
export interface WithColorMode {
	colorMode?: BaseColorMode;
}
export interface WithShadow {
	isShadow?: boolean;
}
export interface WithLoading {
	loading?: boolean;
}

export interface WithFullWidth {
	fullWidth?: boolean;
}

export interface WithFullHeight {
	fullHeight?: boolean;
}

export abstract class BaseElementProps {
	/**
	 * Custom className of element
	 */
	className?: string;
	/**
	 * Custom style of element
	 */
	style?: React.CSSProperties;
	/**
	 * Reference to the element
	 */
	ref?: any;
	/**
	 * Custom style of element
	 */
	sx?: SxProps<Theme>;
	/**
	 * Element id
	 */
	id?: string;
	/**
	 * id for tests
	 */
	private testId?: string;
	/**
	 * Button usage description
	 */
	description?: string;
	constructor(props: BaseElementProps) {
		generateConstructor(this, props);
		if (props.testId) {
			(this as any)['data-testid'] = props.testId;
			delete this.testId;
		}
	}
}

export abstract class BaseColorProps extends BaseElementProps {
	/**
	 * What color should the element be?
	 */
	color?: BaseColors;
	constructor(props: BaseColorProps) {
		super(props);
		this.color = props.color || 'primary';
	}
}

export abstract class BaseBackgroundColorProps extends BaseElementProps {
	/**
	 * background color of line
	 */
	backgroundColor?: BaseColors;
	constructor(props: BaseBackgroundColorProps) {
		super(props);
		this.backgroundColor = props.backgroundColor || 'white';
	}
}

export abstract class BaseCallbackProps
	extends BaseColorProps
	implements WithLoading
{
	/**
	 * Text label
	 */
	label: string;
	/**
	 * Is the button disabled?
	 */
	disabled?: boolean;
	/**
	 * Is the button loading?
	 */
	loading?: boolean;
	/**
	 * Is there a Badge button?
	 */
	isWithBadge?: boolean;
	constructor(props: BaseCallbackProps) {
		super(props);
		this.label = props.label;
	}
}

export abstract class BaseInputProps
	extends BaseCallbackProps
	implements WithBaseSize, WithBaseVariants, WithShadow
{
	size?: BaseSize;
	variant?: BaseVariants;
	/**
	 * Is the button with shading?
	 */
	isShadow?: boolean | undefined;

	constructor(props: BaseButtonProps) {
		super(props);
		this.variant = props.variant || 'fill';
	}
}

export abstract class BaseButtonProps
	extends BaseCallbackProps
	implements WithBaseSize, WithBaseVariants, WithShadow
{
	/**
	 * icon in the center of the button
	 */
	icon?: JSX.Element;
	/**
	 * Which function will be activated when the button is clicked?
	 */
	onClick?: () => void;
	/**
	 * Is the button selected?
	 */
	isSelected?: boolean;
	/**
	 * Is the button clickable?
	 */
	isClickable?: boolean;
	size?: BaseSize;
	variant?: BaseVariants;
	/**
	 * Is the button with shading?
	 */
	isShadow?: boolean | undefined;

	constructor(props: BaseButtonProps) {
		super(props);
		this.isClickable = getIsButtonClickable(props);
		this.variant = props.variant || 'fill';
	}
}
