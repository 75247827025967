import { ButtonActionOptionIcons } from '../../base-types/BaseTypes';
import { generateConstructor } from '../../base-types/utils';
import { ButtonProps } from '../button/types';

export class ChipProps extends (ButtonProps as new (
	props: ButtonProps
) => Omit<ButtonProps, 'icon'>) {
	/**
	 * icon in the center of the button
	 */
	action?: ButtonActionOptionIcons;
	/**
	 * Which function will be activated when the end icon is clicked?
	 */
	onStartIconClick?: (value?: any) => void;
	/**
	 * Which function will be activated when the end icon is clicked?
	 */
	onEndIconClick?: (value?: any) => void;

	constructor(props: ChipProps) {
		super(props);
		this.variant = props.variant || 'outline';
		this.size = props.size || 'small';
		generateConstructor(this, props);
	}
}
