import PageBodyComponent from './navigation/components/PageBodyComponent';
import PageHeaderComponent from './navigation/components/PageHeaderComponent';
import PageSidebarComponent from './navigation/components/PageSidebarComponent';
import { pageComponentByPath } from './navigation/urls';
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import useLayoutService from 'services/layout/LayoutService';
import useRouterService from 'services/router/RouterService';
import Page from 'stories/components/page/Page';
import PageLogo from 'stories/components/page/page-logo/PageLogo';

function AppRouters(): JSX.Element {
	const { currentPageConfiguration } = useRouterService();
	const { loading } = useLayoutService();
	return (
		currentPageConfiguration && (
			<Page
				header={
					currentPageConfiguration.showHeader ? (
						<PageHeaderComponent {...currentPageConfiguration.header} />
					) : undefined
				}
				sidebar={
					currentPageConfiguration.showSidebar ? (
						<PageSidebarComponent {...currentPageConfiguration.sidebar} />
					) : undefined
				}
				body={
					<PageBodyComponent
						{...currentPageConfiguration.body}
						loading={loading}
						content={
							<Routes>
								{Object.entries(pageComponentByPath).map(([path, component], index) => (
									<Route key={index} path={path} element={component} />
								))}
							</Routes>
						}
					/>
				}
				logo={<PageLogo {...currentPageConfiguration.body} />}
			/>
		)
	);
}
export default AppRouters;
