import { useState, useEffect } from 'react';

interface ScreenState {
	width: number;
	isMobile: boolean;
	isDesktop: boolean;
}

function useScreenService(): ScreenState {
	const mobileMaxWidth = 960;
	const debounceDelay = 150;
	const [width, setWidth] = useState(window.innerWidth);

	useEffect(() => {
		let timeoutId: NodeJS.Timeout | null = null;

		const handleResize = (): void => {
			if (timeoutId) clearTimeout(timeoutId); // ביטול debounce קודם
			timeoutId = setTimeout(() => {
				setWidth(window.innerWidth); // עדכון הרוחב רק אחרי זמן debounce
			}, debounceDelay);
		};

		window.addEventListener('resize', handleResize);

		return (): void => {
			if (timeoutId) clearTimeout(timeoutId); // ניקוי timeout
			window.removeEventListener('resize', handleResize); // הסרת המאזין
		};
	}, [debounceDelay]);
	const isMobile = width <= mobileMaxWidth;

	return { width, isMobile, isDesktop: !isMobile };
}

export default useScreenService;
