import React, { createContext, useContext, useState } from 'react';

interface LayoutServiceState {
	loading: boolean;
	setLoading: (value: boolean) => void;
	sidebarOpenByMobile: boolean;
	setSidebarOpenByMobile: (value: boolean) => void;
}

const LayoutServiceContext = createContext<LayoutServiceState | undefined>(
	undefined
);

export const LayoutServiceProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [sidebarOpenByMobile, setSidebarOpenByMobile] = useState<boolean>(false);

	return (
		<LayoutServiceContext.Provider
			value={{ loading, setLoading, setSidebarOpenByMobile, sidebarOpenByMobile }}
		>
			{children}
		</LayoutServiceContext.Provider>
	);
};

export default function useLayoutService(): LayoutServiceState {
	const context = useContext(LayoutServiceContext);
	if (!context) {
		throw new Error(
			'useLayoutService must be used within a LayoutServiceProvider'
		);
	}
	return context;
}
