import { BaseColorProps, BaseVariants } from '../../base-types/BaseTypes';
import { generateConstructor } from '../../base-types/utils';

export class ComponentLoaderProps extends BaseColorProps {
	/**
	 * variant of the loader
	 */
	variant?: BaseVariants;

	constructor(props: ComponentLoaderProps) {
		super(props);
		generateConstructor(this, props);
	}
}
