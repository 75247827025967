import { BaseButtonProps, BaseElementProps } from './BaseTypes';

export function generateConstructor(
	instance: BaseElementProps,
	props: BaseElementProps
): void {
	Object.keys(instance).forEach((key) => {
		const localKey = key as keyof BaseElementProps;
		if (localKey in props) {
			if (instance[localKey] === undefined) {
				instance[localKey] = props[localKey];
			} else if (props[localKey] === undefined) {
				instance[localKey] = undefined;
			}
		}
	});
}
export function createInstance<T>(
	props: T,
	type: new (...args: any[]) => T
): T {
	return new type(props);
}

export const getIsButtonClickable = (props: BaseButtonProps): boolean => {
	if (props.disabled) {
		return false;
	}
	if (props.isClickable !== undefined) {
		return props.isClickable;
	}
	const value = isFunctionExists(props.onClick);
	return value;
};

export const isFunctionExists = (
	func: ((value?: any) => any) | undefined
): boolean => {
	return !!func && func?.name !== 'mockConstructor';
};
