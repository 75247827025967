import { Tooltip } from '@mui/material';
import React from 'react';

const getTooltip = ({
	component,
	description,
}: {
	component: JSX.Element;
	description?: string;
}): JSX.Element => {
	if (description) {
		return (
			<Tooltip title={description} PopperProps={{ disablePortal: true }}>
				{component}
			</Tooltip>
		);
	}
	return component;
};
export default getTooltip;
