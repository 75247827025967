// import { Button } from "@mui/material";
import { createInstance } from '../../base-types/utils';
import { getBadge } from '../../small-components/badge/Badge';
import ComponentLoader from '../../small-components/component-loader/ComponentLoader';
import getTooltip from '../../small-components/tooltip/Tooltip';
import styles from './tab.module.scss';
import { TabProps } from './types';
import Button from '@mui/material/Button';
import classNames from 'classnames';
import React from 'react';

const Tab = (_props: TabProps): JSX.Element => {
	const {
		label,
		color,
		disabled,
		onClick,
		orientation,
		selected,
		className,
		description,
		colorMode,
		isTabsTitle,
		loading,
		isWithBadge,
		...otherProps
	} = createInstance(_props, TabProps);

	const isClickable = !isTabsTitle && !disabled;

	const classes = classNames(
		styles.tab,
		isTabsTitle && styles.isTabsTitle,
		color && styles[color],
		styles.fill,
		colorMode && styles[colorMode],
		!color && styles.withoutColor,
		color && colorMode === 'dark' && !selected && styles.bold,
		selected && styles.selected,
		isClickable && styles.isClickable,
		orientation && styles[orientation],
		disabled && styles.disabled,
		loading && styles.loading,
		className
	);
	const handleOnClick: () => void | undefined = () => {
		if (onClick && !disabled && !isTabsTitle && isClickable) {
			onClick();
		}
	};

	const renderChildren = (): string => {
		return label;
	};

	const contentClasses = classNames(
		styles.content,
		selected && styles.selected,
		loading && styles.loaderContainer
	);
	return getBadge({
		color,
		isWithBadge,
		fullWidth: orientation === 'horizontal',
		component: getTooltip({
			description: description || label,
			component: (
				<Button
					variant={colorMode === 'dark' ? 'contained' : 'text'}
					className={classes}
					disabled={disabled || !onClick}
					onClick={handleOnClick}
					disableRipple={!isClickable}
					{...otherProps}
				>
					<>
						{(label || loading) && (
							<div className={contentClasses}>
								{loading && (
									<ComponentLoader
										color={color}
										variant={colorMode === 'dark' ? 'fill' : 'outline'}
									/>
								)}
								{!loading && renderChildren()}
							</div>
						)}
					</>
				</Button>
			),
		}),
	});
};
export default Tab;
