import { createInstance } from '../../../base-types/utils';
import Tab from '../../tab/Tab';
import styles from './page-sidebar.module.scss';
import { PageSidebarProps } from './types';
import { Box as MuiBox } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import LanguageSelector from 'small-components/LanguageSelector';
import { IconButton } from 'stories/components/icon-button/IconButton';

export const PageSidebar = (_props: PageSidebarProps): JSX.Element => {
	const {
		className,
		backgroundColor,
		tabsProps,
		colorMode,
		onCloseByMobile,
		isOpenByMobile,
		...otherProps
	} = createInstance(_props, PageSidebarProps);

	const sidebarClasses = classNames(
		styles.sidebar,
		isOpenByMobile && styles.openOnMobile,
		backgroundColor && styles[backgroundColor],
		colorMode && styles[colorMode],
		className
	);
	const tabsContainerClasses = classNames(
		styles.tabsContainer,
		backgroundColor && styles[backgroundColor],
		colorMode && styles[colorMode]
	);

	const getTabs = (): JSX.Element[] => {
		return (
			tabsProps?.map((t, index) => (
				<Tab
					key={index}
					{...t}
					colorMode={colorMode}
					color={backgroundColor}
					orientation="horizontal"
				/>
			)) || []
		);
	};

	const onCloseByMobileClick = (): void => {
		if (onCloseByMobile) {
			onCloseByMobile();
		}
	};
	return (
		<MuiBox className={sidebarClasses} {...otherProps}>
			<MuiBox className={styles.contentContainer}>
				<MuiBox className={styles.closeButtonContainer}>
					<IconButton
						label='"close'
						action="close"
						size="small"
						variant="text"
						color={backgroundColor}
						onClick={onCloseByMobileClick}
					/>
				</MuiBox>
				{tabsProps && <MuiBox className={tabsContainerClasses}>{getTabs()}</MuiBox>}
			</MuiBox>
			{<LanguageSelector color={backgroundColor || 'black'} />}
		</MuiBox>
	);
};
export default PageSidebar;
