import { createInstance } from '../../../base-types/utils';
import Tab from '../../tab/Tab';
import styles from './page-header.module.scss';
import { PageHeaderProps } from './types';
import { Box as MuiBox } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { IconButton } from 'stories/components/icon-button/IconButton';

export const PageHeader = (_props: PageHeaderProps): JSX.Element => {
	const {
		className,
		backgroundColor,
		tabsProps,
		colorMode,
		onMenuClick,
		...otherProps
	} = createInstance(_props, PageHeaderProps);

	const headerClasses = classNames(
		styles.header,
		backgroundColor && styles[backgroundColor],
		colorMode && styles[colorMode],
		className
	);
	const tabsContainerClasses = classNames(styles.tabsContainer);

	const getTabs = (): JSX.Element[] | undefined => {
		return tabsProps?.map((t, index) => (
			<Tab
				key={index}
				{...t}
				colorMode={colorMode}
				color={backgroundColor}
				orientation="vertical"
			/>
		));
	};

	return (
		<MuiBox className={headerClasses} {...otherProps}>
			{tabsProps && <MuiBox className={tabsContainerClasses}>{getTabs()}</MuiBox>}
			<IconButton
				className={styles.menuButtonBox}
				label=""
				size="medium"
				action="menuOpen"
				color={backgroundColor}
				variant={colorMode === 'dark' ? 'fill' : 'text'}
				onClick={onMenuClick || undefined}
			/>
		</MuiBox>
	);
};
export default PageHeader;
