import { ScoreStatus } from '../base-types/BaseTypes';
import { Button } from '../components/button/Button';
import { Input } from '../components/input/Input';
import { ScoreLine } from '../components/score-line/ScoreLine';
import { ScoreLineProps } from '../components/score-line/types';
import React from 'react';

const getRandomScoreLineProps = (index: number): ScoreLineProps => {
	const statuses: ScoreStatus[] = ['bad', 'good', 'excellent', 'unsatisfactory'];
	const names: string[] = [
		'אברהם אברהמי',
		'ישראל ישראלי',
		'מאיר מאירי',
		'ראובן ראובני שם ארוך מאוד',
	];
	return {
		title: `${names[Math.floor(Math.random() * names.length)]} | ${index}`,
		value: '8:00',
		isWithStatusButton: true,
		status: statuses[Math.floor(Math.random() * statuses.length)],
		onStatusButtonClick: () => null,
	};
};

const StoryScoreLine = (props: ScoreLineProps): JSX.Element => {
	const [isApproved, setIsApproved] = React.useState(false);
	const [isStatusLoading, setIsStatusLoading] = React.useState(false);
	const [isToCloseDropdown, setIsToCloseDropdown] = React.useState(false);
	const [note, setNote] = React.useState<string>('');

	const args: ScoreLineProps = {
		...props,
		isApproved: isApproved,
		isStatusLoading: isStatusLoading,
		isDropdownFunctionalityDone: isToCloseDropdown,
		isWithNote: !!note,
		dropdownElements: [
			<Input
				key={'add'}
				label="הוסף הערה"
				fullWidth
				value={note}
				size="medium"
				placeholder="הערה"
				onChange={(v) => setNote(v)}
			/>,
			<Button
				key={'save'}
				label="שמור הערה"
				fullWidth
				size="medium"
				onClick={() => setIsToCloseDropdown(true)}
			/>,
		],
		onStatusButtonClick: () => {
			setIsStatusLoading(true);
			setTimeout(() => {
				setIsApproved(!isApproved);
				setIsStatusLoading(false);
			}, 600);
		},
	};
	return <ScoreLine {...args} />;
};
export const getScoreLinesWithStates = (amount: number): JSX.Element[] => {
	const scoreLines: JSX.Element[] = [];
	for (let i = 1; i <= amount; i++) {
		scoreLines.push(<StoryScoreLine {...getRandomScoreLineProps(i)} />);
	}
	return scoreLines;
};
