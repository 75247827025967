import {
	BaseBackgroundColorProps,
	BaseColorMode,
	WithColorMode,
} from '../../../base-types/BaseTypes';
import { generateConstructor } from '../../../base-types/utils';

export class PageLogoProps
	extends BaseBackgroundColorProps
	implements WithColorMode
{
	colorMode?: BaseColorMode;
	constructor(props: PageLogoProps) {
		super(props);
		generateConstructor(this, props);
		this.colorMode = props.colorMode || 'light';
	}
}
