import { createInstance } from '../../base-types/utils';
import styles from './toggle-group.module.scss';
import { ToggleGroupProps } from './types';
import {
	ToggleButtonGroup,
	ToggleButton as MuiToggleButton,
} from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import getTooltip from 'stories/small-components/tooltip/Tooltip';

export const ToggleGroup = (_props: ToggleGroupProps): JSX.Element => {
	const {
		className,
		color,
		orientation,
		components,
		handleChange,
		variant,
		value,
		size,
		fullWidth,
		...otherProps
	} = createInstance(_props, ToggleGroupProps);
	const classes = classNames(
		styles.toggleGroup,
		color && styles[color],
		variant && styles[variant],
		size && styles[size],
		orientation && styles[orientation],
		fullWidth && styles.fullWidth,
		className
	);

	const renderComponents = (): JSX.Element[] =>
		components.map((element, index) =>
			getTooltip({
				description: element.description,
				component: (
					<MuiToggleButton
						key={index}
						value={element.value}
						className={classNames(
							styles.child,
							size && styles[size],
							color && styles[color],
							variant && styles[variant],
							orientation && styles[orientation],
							index === 0 && styles.firstChild,
							index === components.length - 1 && styles.lastChild,
							value === element.value && styles.selected
						)}
					>
						{element.title || element.icon}
					</MuiToggleButton>
				),
			})
		);

	return (
		<ToggleButtonGroup
			className={classes}
			onChange={handleChange}
			orientation={orientation}
			exclusive
			value={value}
			aria-label="Platform"
			{...otherProps}
		>
			{renderComponents()}
		</ToggleButtonGroup>
	);
};
export default ToggleGroup;
