import { createInstance } from '../../base-types/utils';
import styles from './form-card.module.scss';
import { FormCardProps } from './types';
import { Box as MuiBox } from '@mui/material';
import classNames from 'classnames';
import React from 'react';

export const FormCard = (_props: FormCardProps): JSX.Element => {
	const { className, color, content, fullWidth, title, footer, ...otherProps } =
		createInstance(_props, FormCardProps);

	const classes = classNames(
		styles.formCard,
		color && styles[color],
		fullWidth && styles.fullWidth,
		className
	);

	return (
		<MuiBox className={classes} {...otherProps}>
			{title && <MuiBox className={styles.title}>{title}</MuiBox>}
			{content && <MuiBox className={styles.content}>{content}</MuiBox>}
			{footer && <MuiBox className={styles.footer}>{footer}</MuiBox>}
		</MuiBox>
	);
};
