import {
	BaseColorProps,
	BaseColors,
	BaseTransparency,
	BaseOrientation,
	BasePosition,
	BaseThicknesses,
} from '../../base-types/BaseTypes';
import { generateConstructor } from '../../base-types/utils';

export class DividerProps extends BaseColorProps {
	/**
	 * What color should the element be?
	 */
	color?: BaseColors;
	/**
	 * variant of divider
	 */
	variant?: 'fullWidth' | 'inset' | 'middle';
	/**
	 * orientation of the divider
	 */
	orientation?: BaseOrientation;
	/**
	 * thickness of the divider
	 */
	thickness?: BaseThicknesses;
	/**
	 * text on the divider
	 */
	text?: string;
	/**
	 * position of the text on the divider
	 */
	textPosition?: BasePosition;
	/**
	 * is the divider border bold?
	 */
	borderTransparency?: BaseTransparency;
	/**
	 * is the text on the divider bold?
	 */
	isTextHighlighted?: boolean;

	constructor(props: DividerProps) {
		super(props);
		generateConstructor(this, props);
		this.variant = props.variant || 'fullWidth';
		this.orientation = props.orientation || 'vertical';
		this.thickness = props.thickness || 'normal';
		this.textPosition = props.textPosition || 'right';
		this.isTextHighlighted =
			typeof props.isTextHighlighted === 'boolean'
				? props.isTextHighlighted
				: true;
		this.borderTransparency = props.borderTransparency || 'medium';
	}
}
