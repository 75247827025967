import { createInstance } from '../../../base-types/utils';
import { LinearProgress } from '../../linear-progress/LinearProgress';
import styles from './page-body.module.scss';
import { PageBodyProps } from './types';
import { Box as MuiBox } from '@mui/material';
import classNames from 'classnames';
import React from 'react';

export const PageBody = (_props: PageBodyProps): JSX.Element => {
	const {
		className,
		content,
		backgroundColor,
		loaderColor,
		loading,
		colorMode,
		...otherProps
	} = createInstance(_props, PageBodyProps);
	const classes = classNames(
		styles.container,
		backgroundColor && styles[backgroundColor],
		colorMode && styles[colorMode],
		className
	);

	return (
		<MuiBox className={classes} {...otherProps}>
			<MuiBox
				className={classNames(
					styles.linearProgressContainer,
					loading && styles.show
				)}
			>
				<LinearProgress isShow={loading} color={loaderColor} />
			</MuiBox>
			{content}
		</MuiBox>
	);
};
export default PageBody;
