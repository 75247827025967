import {
	BaseBackgroundColorProps,
	BaseColorMode,
	WithColorMode,
} from '../../../base-types/BaseTypes';
import { generateConstructor } from '../../../base-types/utils';
import { TabProps } from '../../tab/types';

export class PageHeaderProps
	extends BaseBackgroundColorProps
	implements WithColorMode
{
	/**
	 * tab in page header
	 */
	tabsProps?: TabProps[];
	colorMode?: BaseColorMode;
	onMenuClick?: () => void;
	constructor(props: PageHeaderProps) {
		super(props);
		generateConstructor(this, props);
		this.colorMode = props.colorMode || 'dark';
		this.backgroundColor = props.backgroundColor || 'gray';
	}
}
