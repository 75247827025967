import { createInstance } from '../../base-types/utils';
import styles from './page.module.scss';
import { PageProps } from './types';
import { Box as MuiBox } from '@mui/material';
import classNames from 'classnames';

export const Page = (_props: PageProps): JSX.Element => {
	const { className, header, sidebar, body, logo, ...otherProps } =
		createInstance(_props, PageProps);

	const classes = classNames(styles.page, className);

	return (
		<MuiBox className={classes} {...otherProps}>
			{header && (
				<MuiBox className={styles.logoAndHeaderContainer}>
					{logo && logo}
					{header}
				</MuiBox>
			)}
			<MuiBox className={styles.bodyAndSidebarContainer}>
				{sidebar && sidebar}
				{body && body}
			</MuiBox>
		</MuiBox>
	);
};
export default Page;
