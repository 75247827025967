import { BaseBackgroundColorProps } from '../../base-types/BaseTypes';
import { generateConstructor } from '../../base-types/utils';

export class ListProps extends BaseBackgroundColorProps {
	/**
	 * list of box children
	 */
	components?: JSX.Element[];

	constructor(props: ListProps) {
		super(props);
		generateConstructor(this, props);
	}
}
